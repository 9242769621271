// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

export const Spinner = ({ label, t }) => (
  <div tabIndex={0} aria-label="loading" className="spinner">
    <span className="spinner__icon">
      <i className="fas fa-sync-alt fa-spin" />
    </span>
    <span className="spinner__label">{label || t('common.loading')}</span>
  </div>
);

Spinner.propTypes = {
  label: PropTypes.string,
  t: PropTypes.func.isRequired,
};

Spinner.defaultProps = {
  label: null,
};

export default withTranslation()(Spinner);
